// Plyr allows us to pass in custom controls for our vimeo embed. It uses the sprite in `static/` for the svgs

// for index where only full screen and volume controls are displayed
export const controlsFullScreenVolume = [
  "<div class='plyr__controls'>",
  "<button class='plyr__controls__item plyr__control' type='button' data-plyr='fullscreen'>",
  "<svg class='icon--pressed' aria-hidden='true' focusable='false'>",
  "<use xlink:href='vid.svg#plyr-exit-fullscreen'></use>",
  "</svg>",
  "<svg class='icon--not-pressed' aria-hidden='true' focusable='false'>",
  "<use xlink:href='vid.svg#plyr-enter-fullscreen'></use></svg>",
  "<span class='label--pressed plyr__sr-only'>Exit fullscreen</span>",
  "<span class='label--not-pressed plyr__sr-only'>Enter fullscreen</span>",
  "</button>",
  "<button type='button' class='plyr__control' data-plyr='mute'>",
  "<svg class='icon--pressed' aria-hidden='true' focusable='false'>",
  "<use xlink:href='vid.svg#plyr-muted'></use></svg>",
  "<svg class='icon--not-pressed' aria-hidden='true' focusable='false'>",
  "<use xlink:href='vid.svg#plyr-volume'></use></svg>",
  "<span class='label--pressed plyr__sr-only'>Unmute</span>",
  "<span class='label--not-pressed plyr__sr-only'>Mute</span>",
  "</button>",
  "</div>",
].join("")

// for project page which has more controls
export const controlsPlayPause = `
    <button type="button" class="plyr__control plyr__control--overlaid" data-plyr="play" aria-label="Play"><svg aria-hidden="true" focusable="false"><use xlink:href="/vid.svg#plyr-play"></use></svg><span class="plyr__sr-only">Play</span></button>
    <div class="plyr__controls">
    <button type="button" class="plyr__controls__item plyr__control" aria-label="Play, {title}" data-plyr="play">
        <svg class="icon--pressed" aria-hidden="true" ><use xlink:href="/vid.svg#plyr-pause"></use></svg>
        <svg class="icon--not-pressed" aria-hidden="true" ><use xlink:href="/vid.svg#plyr-play"></use></svg>
        <span class="label--pressed plyr__sr-only" >Pause</span>
        <span class="label--not-pressed plyr__sr-only">Play</span>
    </button>
    <div class="plyr__controls__item plyr__progress__container">
        <div class="plyr__progress"><input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" autocomplete="off" role="slider" aria-label="Seek" aria-valuemin="0" aria-valuemax="0" aria-valuenow="0" id="plyr-seek-4836" aria-valuetext="00:00 of 00:00" seek-value="19.95279529362032" style="--value:0%;">
        <progress class="plyr__progress__buffer" min="0" max="100" value="0" role="progressbar" aria-hidden="true">% buffered</progress>
        <span class="plyr__tooltip">00:00</span>
        </div>
    </div>
    <div class="plyr__controls__item plyr__time plyr__time--current" aria-label="Current time">00:00</div>

    <div class="plyr__controls__item plyr__volume">
        <button type="button" class="plyr__control plyr__control--pressed" data-plyr="mute">
            <svg class="icon--pressed" aria-hidden="true" focusable="false">
            <use xlink:href="/vid.svg#plyr-muted"></use></svg><svg class="icon--not-pressed" aria-hidden="true" focusable="false">
            <use xlink:href="/vid.svg#plyr-volume"></use></svg>
            <span class="label--pressed plyr__sr-only">Unmute</span>
            <span class="label--not-pressed plyr__sr-only">Mute</span>
        </button>
        <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" role="slider" aria-label="Volume" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" id="plyr-volume-4836" aria-valuetext="0.0%" style="--value:0%;">
    </div>
    <button type="button" class="plyr__controls__item plyr__control" data-plyr="fullscreen">
        <svg class="icon--pressed" aria-hidden="true" ><use xlink:href="/vid.svg#plyr-exit-fullscreen"></use></svg>
        <svg class="icon--not-pressed" aria-hidden="true" ><use xlink:href="/vid.svg#plyr-enter-fullscreen"></use></svg>
        <span class="label--pressed plyr__sr-only" >Exit fullscreen</span>
        <span class="label--not-pressed plyr__sr-only">Enter fullscreen</span>
    </button>
</div>
`
