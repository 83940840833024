import React, { Component } from "react"

class Player extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  componentDidMount() {
    const plyr = require("plyr")
    const options = {
      iconUrl: "/vid.svg",
      clickToPlay: false,
      hideControls: false,
      autoplay: true,
      muted: true,
      storage: { enabled: false },
      ...this.props.options,
    }
    if (this.props.controls) {
      options["controls"] = this.props.controls
    }
    this.player = new plyr(`#${this.props.id}`, options)

    if (options.muted === true) {
      this.player.on("ready", () => {
        this.player.muted = true
      })
    }

    if (this.props.previewImage) {
      this.player.poster = this.props.previewImage
    }

    if (this.props.onPlaying) {
      this.player.on("playing", () => this.props.onPlaying(true))
      this.player.on("pause", () => this.props.onPlaying(false))
    }
  }

  onClick() {
    if (this.props && this.props.onClickAction) {
      this.props.onClickAction()
    }
  }

  onBlur() {
    if (this.props && this.props.onBlurAction) {
      this.props.onBlurAction()
    }
  }

  render() {
    return (
      <div
        role="presentation"
        onClick={this.onClick}
        onBlur={this.onBlur}
        className="plyr__video-embed"
        id={this.props.id}
      >
        <iframe title={this.props.title} src={this.props.vimeoLink} allow="autoplay; fullscreen"></iframe>
      </div>
    )
  }
}

export default Player
