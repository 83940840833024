import { ScrollbarPlugin } from 'smooth-scrollbar';

export class DisableScrollPlugin extends ScrollbarPlugin {
  static pluginName = 'disableScroll';

  static defaultOptions = {
    direction: null,
  };

  transformDelta(delta) {
    if (this.options.direction) {
      delta[this.options.direction] = 0;
    }

    return { ...delta };
  }
}

export class AnchorPlugin extends ScrollbarPlugin {
  static pluginName = 'anchor';

  static defaultOptions = {
    hash: null,
  };

  onInit() {
    const { hash } = this.options;
    const { scrollbar } = this;

    if (hash) {
      requestAnimationFrame(function () {
        const el = document.querySelector(`#${hash}`);
        if (el) {
          scrollbar.scrollIntoView(el, {
            offsetLeft: 5000,
          });
        }
      });
    }
  }
}