/* eslint-disable no-useless-escape */
export function slug(string) {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const p = new RegExp(a.split("").join("|"), "g")

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

export const getVimeoLink = (
  vimeoLink,
  options = { title: 0, byline: 0, portrait: 0 }
) => {
  const params = new URLSearchParams(options)
  if (vimeoLink) {
    const vimeoId = vimeoLink.substr(
      vimeoLink.lastIndexOf("/") + 1,
      vimeoLink.length
    )
    return `https://player.vimeo.com/video/${vimeoId}?${params}`
  }
  return ""
}
